<script setup lang="ts">
import { XIcon } from 'lucide-vue-next';
import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useForwardPropsEmits,
} from 'radix-vue';
import { type HTMLAttributes } from 'vue';

const props = defineProps<DialogContentProps & { class?: HTMLAttributes['class']; hideCloseButton?: boolean }>();
const emits = defineEmits<DialogContentEmits>();

const delegatedProps = computed(() => {
  const {
    class: _,
    ...delegated
  } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <DialogPortal>
    <DialogOverlay class="fixed inset-0 z-50 bg-dark/50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
    <DialogContent
      v-bind="forwarded"
      :class="
        cn(
          'overflow-y-auto fixed left-1/2 top-1/2 z-50 grid w-full max-w-5xl -translate-x-1/2 -translate-y-1/2 gap-4 bg-white p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-2 data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-2 rounded-xl max-h-[90dvh]',
          props.class,
        )"
    >
      <slot />

      <DialogClose
        v-if="!hideCloseButton"
        class="absolute right-4 top-4 z-30 flex size-10 items-center justify-center rounded-full bg-grey-light transition-all focus:outline-none disabled:pointer-events-none data-[state=open]:bg-grey-light data-[state=open]:text-dark"
      >
        <XIcon class="size-6" />
      </DialogClose>
    </DialogContent>
  </DialogPortal>
</template>
